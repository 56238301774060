.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.Button {
  display: block;
  width: 250px;
  height: 56px;
  background-color: #56c4c4;
  border-width: 0;
  border-radius: 12px;
  margin-top: 10px;
  font-size: 22px;
  color: black;
}

.Button-toggled {
  background-color: #fadb2d;
}

.App-gradient {
  background: rgb(25, 52, 96);
  background: linear-gradient(
    270deg,
    rgba(25, 52, 96, 1) 0%,
    rgba(28, 89, 148, 1) 69%,
    rgba(76, 171, 224, 1) 100%
  );
}